// Angular:
import { Component, ViewChild, ElementRef } from '@angular/core';
// Common:
import { WidgetScreenPositionX, WidgetScreenPositionY } from '@nurtureboss/common/dist/types/brandings';
import { LanguageCode } from '@nurtureboss/common/dist/types/settings';
// Services:
import {
  UsersService,
  AuthenticationService,
  ToastService,
  LoaderService,
  BrandingsService,
  MediaService,
  SubscriptionsService,
  SettingsService,
  EmailSettingsInterface,
  IntegrationService,
  EmailsService,
  SettingType,
  VoiceVLAService,
} from '@app/_services';
// Bootstrap:
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
// Types:
import { User } from '@nurtureboss/common/dist/types/users';
import { IntegrationSettings, TemplateSettings, PropertySettings } from '@nurtureboss/common/dist/types/settings';
import { Branding } from '@nurtureboss/common/dist/types/brandings';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.less']
})
export class AccountComponent {
  @ViewChild('testVariable') myTestDiv: ElementRef<HTMLInputElement>;

  localUserData: any;
  userData: Partial<User> & { password?: string } = {
    claims: [],
    emailConfig: {
      routingAddress: '',
    },
    assignedNumber: '',
  };
  templateSettings: TemplateSettings = {
    showVisitWebsiteLinks: true,
    showCallUsLinks: true,
    showEmailUsLinks: true,
    sendOutlookHotmailPlainText: false,
    showTranslateLink: false,
    translationLanguage: LanguageCode.SPANISH,
    optionalEmailDisclaimer: null,
  };
  integrationSettings: IntegrationSettings = {
    pauseForInboundMessage: false,
  }
  propertySettings: PropertySettings = {
    emergencyMaintenancePhoneNumber: '',
  }
  savingSettings = false;
  hexInvalid = false;
  brandings: Partial<Branding> & {_id?: string} = {
    fontFamily: 'Alegreya Sans',
    primaryColor: '#235587',
    widget: {
      iconMediaId: null,
      screenPositionX: WidgetScreenPositionX.Right,
      screenPositionY: WidgetScreenPositionY.Bottom,
      xOffset: null,
      yOffset: null,
    }
  };
  brandingWidgetUrl = '';
  fontList: Array<any>;
  fontSuggestions: Array<any>;
  modalOptions: NgbModalOptions;
  emailSettings: EmailSettingsInterface = {
    fromAddress: '',
    mailgunRouteId: '',
    customDomain: '',
  };
  originalRoutingAddress = '';
  originalEmergencyMaintenancePhoneNumber = '';

  constructor(
    private userService: UsersService,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private brandingsService: BrandingsService,
    private modalService: NgbModal,
    private subscriptionService: SubscriptionsService,
    private emailsService: EmailsService,
    private mediaService: MediaService,
    private integrationService: IntegrationService,
    private settingsService: SettingsService,
    private voiceVLAService: VoiceVLAService,
  ) {
      this.localUserData = this.authService.currentUserValue;
      this.loadData();
      this.loadFonts();
      this.modalOptions = { windowClass : 'custom-modal-styles-new'};
  }

  async loadData() {
    this.loaderService.triggerLoader();
    try {

      // Fetch fresh user data.
      const data = await this.userService.getUserData(this.localUserData.user._id).toPromise();
      this.userData = data.result;
      if (this.userData.emailConfig?.routingAddress) {
        this.originalRoutingAddress = this.userData.emailConfig.routingAddress.split('@')[0];
        this.userData.emailConfig.routingAddress = this.userData.emailConfig.routingAddress.split('@')[0];
      }
      if (!this.userData?.emailConfig) {
        this.userData.emailConfig = {
          routingAddress: null,
          mailgunRouteId: null,
        };
      }
      // Default fheo value to make backwards compatible.
      this.userData.fheo = data.result.fheo === false ? data.result.fheo : true;
      const settingsRes = (await this.settingsService.getAllSettings().toPromise()).result;
      if (settingsRes?.emailConfig?.customDomain) {
        this.emailSettings.customDomain = settingsRes.emailConfig.customDomain;
      }
      // TODO: Need to handle users that do not have forwarding address set.
      // this.emailSettings = settingsRes.emailConfig;
  
      if (settingsRes.template) {
        this.templateSettings = { ...this.templateSettings, ...settingsRes.template };
      }
  
      if (settingsRes.integration) {
        this.integrationSettings = { ...this.integrationSettings, ...settingsRes.integration };
      }

      if (settingsRes.integration) {
        this.propertySettings = { ...this.propertySettings, ...settingsRes.property };
        this.originalEmergencyMaintenancePhoneNumber = this.propertySettings.emergencyMaintenancePhoneNumber;
      }
      // Get user brandings
      const brandingData = await this.brandingsService.getBrandings().toPromise();
      this.brandings = brandingData.result;
      // TODO: enable when the custom icon is implemented
      // if (this.brandings.widget.iconMediaId) {
      //   this.mediaService.getById(this.brandings.widget.iconMediaId).subscribe((media) => {
      //     this.brandingWidgetUrl = media.result.url;
      //   });
      // }
      this.loaderService.stopLoader();
    } catch (e) {
      this.toastService.showError('There was an error retrieving your user settings');
      this.loaderService.stopLoader();
    }
  }

  async loadFonts() {
    try {
      const data = await this.brandingsService.loadFonts().toPromise();
      this.fontList = data.items.sort(function(a, b) {
        return (a.family > b.family) ? 1 : -1;
      });
      this.fontSuggestions = [...this.fontList];
    } catch (e) {
      this.toastService.showError('There was an error retrieving system fonts');
    }
  }

  removeAtSignChars() {
    if (this.userData.emailConfig.routingAddress.indexOf('@')) {
      this.userData.emailConfig.routingAddress = this.userData.emailConfig.routingAddress.replace(/@/g,'');
    }
  }

  updateSampleFont() {
    document.getElementById('samplefont').style.fontFamily = this.brandings.fontFamily;
    this.replaceStyle('https://fonts.googleapis.com/css?family=' + this.brandings.fontFamily);
  }

  replaceStyle(url) {
    if (!document.getElementById('styleTag')) {
      const styleTag = document.createElement('link');
      styleTag.rel = 'stylesheet';
      styleTag.id = 'styleTag';
      styleTag.type = 'text/css';
      document.getElementsByTagName('head')[0].appendChild(styleTag);
      this.replaceStyle(url);
    }
    (document.getElementById('styleTag') as HTMLLinkElement).href = url;
  }

  async updateUser() {
    this.savingSettings = true;

    // Normalize email forwarding address between user and settings objects.
    // TODO: Need to update code everywhere so we only store this on one spot.
    try {

      // We update user settings first to get the mailgun
      // routing ID in case we don't have it yet.
      const updatedSettings: any = await this.updateSettings();
      const userUpdates: Partial<User> = {
        propertyName: this.userData.propertyName,
        timezone: this.userData.timezone,
        oneWay: this.userData.oneWay,
        oneWayResponse: this.userData.oneWayResponse,
        callForwarding: this.userData.callForwarding,
        callForwardingNumber: this.userData.callForwardingNumber,
        safeSend: this.userData.safeSend,
        fheo: this.userData.fheo,
      };

      // If we successfully set email settings update user object to reflect.
      if (updatedSettings?.result?.emailConfig) {
        userUpdates.emailConfig = updatedSettings.result.emailConfig;
      }
      // First update properties help on User object.
      await this.userService.updateUser(this.userData._id, userUpdates).toPromise();

      // Only set originalRoutingAddress if it was successfull
      if (userUpdates?.emailConfig?.routingAddress) {
        this.originalRoutingAddress = userUpdates?.emailConfig?.routingAddress;
      }
      this.toastService.showSuccess('Account Updated!');
      this.savingSettings = false;
    } catch (e) {
      this.savingSettings = false;
      this.toastService.showError('There was an error updating account settings.');
    }
  }

  async updateSettings(isCommunicationsUpdate = false) {
    this.savingSettings = true;
    try {
      let updatedEmailSettings: any;
      if (!isCommunicationsUpdate) {
        try {
          updatedEmailSettings = await this.settingsService.saveSettingsByType(SettingType.EmailConfig, this.userData.emailConfig).toPromise();
        } catch (err) {
          if (err == 'Mailgun route address already exists') {
            this.userData.emailConfig.routingAddress = this.originalRoutingAddress.split('@')[0];
            this.toastService.showError('From address already exists, please choose a different one.');
          } else {
            this.toastService.showError('Error saving email from address setting.');
          }
          // no-op. We do not want to stop the rest of user settings from saving becuase of email error.
        }
        await this.integrationService.updatePropertyInfo(this.userData.integrationPropertyId, {
          timezone: this.userData.timezone,
          propertyName: this.userData.propertyName,
        }).toPromise();
      }

      const updatedSettings = await this.settingsService.saveAllSettings({
        template: {
          ...this.templateSettings,
        },
        integration: {
          ...this.integrationSettings,
        },
        property: {
          ...this.propertySettings,
        }
      }).toPromise();

      if (
        this.userData.claims.includes('vla')
        && this.userData.voiceAssistantId
        && this.originalEmergencyMaintenancePhoneNumber !== this.propertySettings.emergencyMaintenancePhoneNumber
      ) {
        try {
          let number = '';
          // Intentionally send empty string to let the service know to remove the destination
          if (this.propertySettings.emergencyMaintenancePhoneNumber) {
            number = '+1' + this.propertySettings.emergencyMaintenancePhoneNumber;
          }
          await this.voiceVLAService.updateCallTransferDestinationNumber(this.userData.voiceAssistantId, 'emergencyMaintenance', number).toPromise();
        } catch (err) {
          this.toastService.showError('There was an error updating the emergency maintenance phone number in the VLA call transfers.');
        }
        this.originalEmergencyMaintenancePhoneNumber = this.propertySettings.emergencyMaintenancePhoneNumber;
      }

      if (isCommunicationsUpdate) {
        this.savingSettings = false;
        this.toastService.showSuccess('Communication Updated!');
        return;
      }
      return {
        ...updatedSettings,
        ...updatedEmailSettings,
      };
    } catch (err) {
      this.savingSettings = false;
      this.toastService.showError('Error updating Communication!');
    }
  }

  saveUserBranding() {
    this.savingSettings = true;
    this.hexInvalid = !new RegExp('^#(?:[0-9a-fA-F]{3}){1,2}$').test(this.brandings.primaryColor);
    if (this.hexInvalid) {
      this.savingSettings = false;
      return;
    }
    this.brandingsService.updateBranding(this.brandings._id, this.brandings).subscribe((updatedBranding) => {
      this.brandings = updatedBranding.result;
      this.savingSettings = false;
      this.toastService.showSuccess('Branding Updated!');
    }, (err) => {
      this.savingSettings = false;
      this.toastService.showError('There was an error updating custom branding.');
    });
  }

  isDevUser() {
    return this.userData.claims.includes('dev');
  }

  onFontSelect(event) {
    this.brandings.fontFamily = event.family;
    document.getElementById('samplefont').style.fontFamily = event.family;
    this.replaceStyle('https://fonts.googleapis.com/css?family=' + event.family);
  }

  onFontCleared(_event) {
    this.brandings.fontFamily = null;
  }

  filterFonts({ query }) {
    const normalQuery = (query || '').toLowerCase();
    this.fontSuggestions = this.fontList.filter((font) => {
      return font.family.toLowerCase().includes(normalQuery);
    });
  }

  updateUserEmail() {
    this.savingSettings = true;
    this.userService.updateUser(this.userData._id, {
      email: this.userData.email
    }).subscribe(() => {
      this.savingSettings = false;
      this.toastService.showSuccess('Settings updated!');
    }, (e) => {
      this.savingSettings = false;
      this.toastService.showError('There was an error updating settings');
    });
  }

  async updateUserPassword() {
    this.savingSettings = true;
    try {
      await this.userService.updateUserPassword(this.userData._id, {
        password: this.userData.password
      }).toPromise();
      this.savingSettings = false;
      this.toastService.showSuccess('Password updated!');
    } catch (e) {
      this.savingSettings = false;
      this.toastService.showError('There was an error updating password');
    }
  }

  async openModal(content, onboarding) {
    if (onboarding) {
      this.loaderService.triggerLoader();

      // Fetch customer invoices to ensure we have invoice to send.
      try {
        const data = await this.subscriptionService.getInvoices().toPromise();
        const invoices = data.result.data.sort((a, b) => +new Date(a.created) - +new Date(b.created));
        if (invoices.length === 0) {
          this.toastService.showError('No invoices found');
          this.loaderService.stopLoader();
          return;
        } else if (invoices[0].status === 'draft') {
          this.toastService.showError('Invoice still generating, please try later.');
          this.loaderService.stopLoader();
        } else {
          this.modalService.open(content).result.then();
          this.loaderService.stopLoader();
        }
      } catch (_e) {
        this.toastService.showError('There was an error retrieving invoices');
        this.loaderService.stopLoader();
      }
    } else {
      this.modalService.open(content).result.then();
      this.loaderService.stopLoader();
    }
  }

  async sendOnboardingEmail(modal) {
   this.savingSettings = true;
   try {
     const templateData = {
       fromEmail: 'customersuccess@nurtureboss.io',
       fromName: 'Nurture Boss Customer Success',
       subject: 'Welcome To Nurture Boss: Next Steps',
       sendTo: this.userData.email,
       templateName: 'initialOnboardingTemplate',
       propertyName: this.userData.propertyName,
       userEmail: this.userData.email
     };
     await this.emailsService.sendEmailTemplate(templateData).toPromise();
     modal.close();
     this.savingSettings = false;
     this.toastService.showSuccess('Email sent!');
   } catch (_e) {
     modal.close();
     this.savingSettings = false;
     this.toastService.showError('There was an error sending email');
   }
  }

  async sendOnboardingFollowUpEmail(modal) {
    this.savingSettings = true;
    try {
      const templateData = {
        fromEmail: 'customersuccess@nurtureboss.io',
        fromName: 'Nurture Boss Customer Success',
        subject: 'Welcome To Nurture Boss: Final Tips',
        sendTo: this.userData.email,
        templateName: 'followUpOnboardingTemplate'
      };
      await this.emailsService.sendEmailTemplate(templateData).toPromise();
      modal.close();
      this.savingSettings = false;
      this.toastService.showSuccess('Email sent!');
    } catch (_e) {
      modal.close();
      this.savingSettings = false;
      this.toastService.showError('There was an error sending email');
    }
  }

  addBotClaim() {
    this.userService.updateUser(this.userData._id, {
      claims: [...this.userData.claims, 'bot']
    }).subscribe((response) => {
      this.localUserData.user = response.result;
      this.toastService.showSuccess('Bot claim added!');
    }, (e) => {
      this.toastService.showError('There was an error adding bot claim');
    });
  }
}

