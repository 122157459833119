// Angular:
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// Services:
import {
  UsersService,
  AuthenticationService,
  SubscriptionsService,
  ToastService,
  LoaderService,
  TextMessagesService,
  BrandingsService,
  GroupsService,
  Group,
  UserAuditsService,
} from '@app/_services';
import { EmailsService } from '@app/_services/emails.service';
import { AdminService } from '@app/_services/admin.service';
// Libs:
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { startCase } from 'lodash';
// Pipes
import { deriveAddressDisplay } from './mimic-account-display.pipe';

interface AccountModel {
  propertyName: string;
  propertyManagementCompany?: string;
  address?: string;
  display?: string;
  churned?: boolean;
  _id: string;
}

interface GroupWithDisplay extends Group {
  display?: string;
}

// Move to commons when back was ready
interface AdditionalContact {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  type: string;
}

let self;

const contactTypes = ['keyContact', 'executiveSponsor', 'champion', 'advocate', 'notAssigned']

const contactTypeOptions = contactTypes.map((type) => ({ text: startCase(type), value: type }));

const additionalContactFormInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  jobTitle: '',
  type: contactTypes[4],
}

const US_STATE_ABBREVIATIONS = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
  'DC', 'PR', 'VI', 'AS', 'GU', 'MP'
];

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less'],
})
export class AdminComponent implements OnInit {
  loading = false;
  userData: any;
  accounts: AccountModel[];
  churnAccounts: AccountModel[];
  suggestions: AccountModel[];
  churnSuggestions: AccountModel[];
  selectedAccount: AccountModel | null;
  showChurn: boolean = false;
  accountToChurn: AccountModel | null;
  churnStatus: any = {};
  createAccountStep: any;
  newAccountInfo: any = {};
  selectedPricingOption: string;
  setupFee: number;
  flatRatePrice: number;
  perUnitPrice: number;
  perUnitTotal: number;
  userBeingCreated: any;
  createUserBusy = false;
  createUserError: any;
  modalOptions: NgbModalOptions;
  saving = false;
  dropdownSettings: any;
  clientSuccessAccountTypes: any;
  startCase = startCase;
  // Group
  newGroupInfo: Group = {};
  isLoadingGroup = false;
  groupError = '';
  groups: Group[] = [];
  suggestionsGroups: Group[] = [];
  selectedGroup: Group = null;
  usersAddedList: GroupWithDisplay[] = [];
  usersList: Group[] = [];
  // Additional Contacts
  hasFormAdditionalContacts = false;
  additionalContactsForm: AdditionalContact = { ...additionalContactFormInitialValues };
  contactTypeOptions = contactTypeOptions;
  additionalContactsError = '';
  twilioRegistrationError: string | null = null;
  @ViewChild("churnStatusModal") churnStatusModal: HTMLElement;

  constructor(
    private userService: UsersService,
    private authService: AuthenticationService,
    private subscriptionService: SubscriptionsService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private modalService: NgbModal,
    private router: Router,
    private emailsService: EmailsService,
    private textMessageService: TextMessagesService,
    private brandingsService: BrandingsService,
    private adminService: AdminService,
    private groupsService: GroupsService,
    private userAuditsService: UserAuditsService,
  ) {
      self = this;
  }

  isAdmin () {
    return !!this.userData?.user?.claims?.includes('admin');
  }

  isPartner () {
    return !!this.userData?.user?.claims?.includes('partner');
  }

  ngOnInit() {
    this.userData = this.authService.currentUserValue;
    this.loadData();
    this.modalOptions = { windowClass : 'custom-modal-styles-new'};
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
  }

  loadData() {
    this.loading = true;
    this.loaderService.triggerLoader();

    // Check if admin, if so fetch all accounts.
    if (
      this.userData.user.claims && this.userData.user.claims.indexOf('admin') > -1 ||
      this.userData.user.claims && this.userData.user.claims.indexOf('partner') > -1
    ) {
      // Only show churn for Connor and Jacob's accounts
      if (this.userData.user._id === "62548d8ef9ad560f8dbadcbe" || this.userData.user._id === "5e4833fd23486751efe39440") {
        this.showChurn = true;
      }
      this.authService.getAllAccounts().subscribe((data) => {
        const filteredArray = data.result.filter(function(v) {
          return v.propertyName && v.propertyName.length > 0;
        }).map((account: any) => {
          if (
            this.userData.user.claims.indexOf('partner') > -1 ||
            this.userData.user.claims.indexOf('admin') > -1
          ) {
            account.display = `${account.propertyManagementCompany} - ${account.propertyName}${deriveAddressDisplay(account.address)}`;
          } else {
            account.display = `${account.propertyName}${deriveAddressDisplay(account.address)}`;
          }
          if (account.churned) {
            account.display = '[CHURNED] ' + account.display;
          }
          return account;
        }).filter((account) => (!account.claims.includes('group') && !account.claims.includes('partner')));
        this.accounts = filteredArray.sort(function(a, b) {
          return (a.propertyName > b.propertyName) ? 1 : -1;
        });
        this.suggestions = [...this.accounts];
        if (this.showChurn) {
          this.churnAccounts = JSON.parse(JSON.stringify(this.accounts));
          this.churnSuggestions = JSON.parse(JSON.stringify(this.suggestions));
        }
        this.loading = false;
        this.loaderService.stopLoader();
      }, (_e) => {
        this.loading = false;
        this.loaderService.stopLoader();
      });
      if (this.isAdmin()) {
        this.loadGroups();
      }
    }
  }

  async openCreateAccountModal(content, isPartner) {
    this.loaderService.triggerLoader();
    this.createAccountStep = 1;
    this.newAccountInfo = {};
    if (!isPartner) {
      const clientSuccessAccountTypes = await this.adminService.getClientSuccessAccountTypes().toPromise();
      this.clientSuccessAccountTypes = clientSuccessAccountTypes.result;
    }
    this.loaderService.stopLoader();
    this.modalService.open(content, {windowClass : 'custom-modal-styles-new create-account-modal'}).result.then((result) => {
      if (result === 'Success') {
        self.toastService.show('New user created. Don\'t forget to set template defaults!', { classname: 'bg-success text-light', delay: 5000 });
        this.resetUserCreateVars();
      }
    }, () => {
      this.resetUserCreateVars();
    });
  }

  resetUserCreateVars() {
    this.createAccountStep = 0;
    this.newAccountInfo = {};
    this.createUserBusy = false;
    this.userBeingCreated = null;
    this.createUserError = null;
  }

  createUserAccount(modal, isPartner) {
    if (isPartner) {
      if (
        !this.newAccountInfo.propertyName ||
        !this.newAccountInfo.emailAddress ||
        !this.newAccountInfo.propertyAddress ||
        !this.newAccountInfo.timezone ||
        !this.newAccountInfo.phoneNumber ||
        !this.newAccountInfo.pmc ||
        !this.newAccountInfo.unitCount ||
        !this.newAccountInfo.propertyRepFirstName ||
        !this.newAccountInfo.propertyRepLastName ||
        !this.newAccountInfo.yourWebsite
      ) {
        this.createUserError = 'Please populate all needed fields.';
        return;
      }
    } else {
      if (
        !this.newAccountInfo.propertyName ||
        !this.newAccountInfo.legalEntityName ||
        !this.newAccountInfo.emailAddress ||
        !this.newAccountInfo.propertyAddress ||
        !this.newAccountInfo.unitCount ||
        !this.newAccountInfo.timezone ||
        !this.newAccountInfo.phoneNumber ||
        !this.newAccountInfo.csm ||
        !this.newAccountInfo.pmc ||
        !this.newAccountInfo.propertyRepFirstName ||
        !this.newAccountInfo.propertyRepLastName ||
        !this.newAccountInfo.yourWebsite
      ) {
        this.createUserError = 'Please populate all needed fields.';
        return;
      }
    }
    const regionalCheck = [
      this.newAccountInfo.regionalFirstName,
      this.newAccountInfo.regionalLastName,
      this.newAccountInfo.regionalEmailAddress,
    ];
    if (
      !regionalCheck.every(x => x) &&
      !regionalCheck.every(x => !x)
      ) {
        this.createUserError = 'If adding regional, name and email are required.';
        return;
    }
    this.createUserBusy = true;

    // Create User
    this.userService.createNewAccount(this.newAccountInfo).subscribe((returnData) => {

      // Generate new subaccount in Twilio and set env. config vars for text service.
      this.textMessageService.generateSubAccount(
        {
          userId: returnData.result.user._id,
          website: this.newAccountInfo.yourWebsite,
          firstName: this.newAccountInfo.propertyRepFirstName,
          lastName: this.newAccountInfo.propertyRepLastName,
        }).subscribe((response) => {
          if (response?.result?.registrationError) {
            this.twilioRegistrationError = response.result.registrationError;
          }
          const addressParts = this.newAccountInfo.propertyAddress.split(' ');
          let propertyState;
          if (addressParts[addressParts.length - 1].toUpperCase() === 'USA') {
            propertyState = addressParts[addressParts.length - 3];
          } else {
            propertyState = addressParts[addressParts.length - 2];
          }

          if (!US_STATE_ABBREVIATIONS.includes(propertyState)) {
            this.createUserError = 'Invalid state found in address. Please ensure address ends with "STATE ZIP" or "STATE ZIP, USA"';
            return;
          }

          this.emailsService.createForwardingRoute(
            returnData.result.user._id,
            this.newAccountInfo.propertyName,
            this.newAccountInfo.emailAddress,
            propertyState
          ).subscribe(() => {
            if (!isPartner) {
              this.userBeingCreated = returnData.result.user;
    
              // Continue to next step 2 (skip integration specific setup).
              this.createAccountStep++;
              this.createUserBusy = false;
            } else {
              this.createUserBusy = false;
              modal.close('Success');
            }
          },
          (_err) => {
            this.createUserBusy = false;
            this.createUserError = 'There was an error creating the property email forwarding route';
          });
      }, (_err) => {
        this.createUserBusy = false;
        this.createUserError = 'There was an error creating the user during twilio sub account creation';
      });
    }, (_err) => {
      this.createUserBusy = false;
      this.createUserError = 'Error creating user';
    });
  }

  createSubscription(modal) {
    this.createUserBusy = true;

    // Checks no missing price value and that price and setup fee are valid prices
    if (
      (this.selectedPricingOption === 'perUnit' && ((!this.perUnitPrice && this.perUnitPrice !== 0) || !this.isValidPrice(this.perUnitPrice)))
      || (this.selectedPricingOption === 'flatRate' && ((!this.flatRatePrice && this.flatRatePrice !== 0) || !this.isValidPrice(this.flatRatePrice)))
      || ((!this.setupFee && this.setupFee !== 0) || !this.isValidPrice(this.setupFee))
    ) {
      this.createUserBusy = false;
      this.createUserError = 'Setup Fee or Pricing is invalid. Must be greater than 0 and not contain more than 2 decimal values. Ex. 1037.50';
      return
    }

    const subObj: any = {
      userId: this.userBeingCreated._id.toString(),
      pricingOption: this.selectedPricingOption,
      stripeCustomerId: this.userBeingCreated.stripeCustomerId,
      ...((this.setupFee || this.setupFee === 0) && { setupFee: this.convertFloatPriceToCents(this.setupFee) }),
      ...(this.selectedPricingOption === 'flatRate' && { amount: this.convertFloatPriceToCents(this.flatRatePrice) }),
      ...(this.selectedPricingOption === 'perUnit' && {
        amount: this.convertFloatPriceToCents(this.perUnitPrice),
        quantity: parseInt(this.userBeingCreated.units)
       }),
    };

    this.subscriptionService.subscribe(subObj).subscribe(() => {
      this.createAccountStep++;
      this.createUserBusy = false;
      modal.close('Success');
    }, (_err) => {
      this.createUserBusy = false;
      this.createUserError = 'Error creating subscription';
    });
  }

  isValidPrice(price) {
    try {
      if (price.toString().split('.')[1]?.length > 2) {
        return false;
      }
      if (price < 0) {
        return false;
      }
      return true;
    } catch (_err) {
      return false;
    }
  }

  convertFloatPriceToCents(floatPrice) {
    return parseInt((floatPrice * 100).toFixed(0));
  }

  async createAccountNextStep(modal, isPartner) {

    // Check if a partner is currently mimicking another account.
    if (this.userData.user.partnerOwnerId) {

      // Re-mimic the original partner account.
      try {
        this.loaderService.triggerLoader();
        await this.authService.adminMimic(this.userData.user.partnerOwnerId).toPromise();
        this.userData = this.authService.currentUserValue;
        this.loaderService.stopLoader();
      } catch (e) {
        // no-op
      }
    }
    this.createUserError = null;
    if (this.createAccountStep === 1) {
      this.createUserAccount(modal, isPartner);
    }
    if (this.createAccountStep === 2) {
      this.createSubscription(modal);
    }
  }

  mimic(account) {
    this.loaderService.triggerLoader();
    this.authService.adminMimic(account._id).subscribe(async (_) => {
      await this.brandingsService.getBrandings(true).toPromise();
      this.loaderService.stopLoader();
      this.userAuditsService.refreshTriggeredUserAudits();
      this.router.navigate(['/dashboard']);
    }, (_e) => {
      this.loaderService.stopLoader();
    });
  }

  async churnAccountSelected() {
    this.loaderService.triggerLoader();
    this.churnStatus = {};
    try {
      this.churnStatus = (await this.userService.churn(this.accountToChurn._id).toPromise()).result;
      this.modalService.dismissAll();
      this.loaderService.stopLoader();
      this.openChurnStatusModal();
    } catch (error) {
      this.loaderService.stopLoader();
      this.modalService.dismissAll();
      this.toastService.showError('There was a problem churning the account');
    }
  }

  filterAccounts({ query }) {
    const normalQuery = (query || '').toLowerCase();
    this.suggestions = this.accounts.filter((account) => {
      const propertyName = (account.propertyName || '').toLowerCase();
      const address = (account.address || '').toLowerCase();
      const pmc = (account.propertyManagementCompany || '').toLowerCase();
      return propertyName.includes(normalQuery) || address.includes(normalQuery) || pmc.includes(normalQuery);
    });
  }

  filterChurnAccounts({ query }) {
    const normalQuery = (query || '').toLowerCase();
    this.churnSuggestions = this.churnAccounts.filter((account) => {
      const propertyName = (account.propertyName || '').toLowerCase();
      const address = (account.address || '').toLowerCase();
      const pmc = (account.propertyManagementCompany || '').toLowerCase();
      return propertyName.includes(normalQuery) || address.includes(normalQuery) || pmc.includes(normalQuery);
    });
  }

  onAccountSelect(event) {
    this.selectedAccount = event;
  }

  onChurnAccountSelect(event) {
    this.accountToChurn = event;
  }

  onAccountCleared(_event) {
    this.selectedAccount = null;
  }

  onChurnAccountCleared(_event) {
    this.accountToChurn = null;
  }

  onPmcSelect(event) {
    this.newAccountInfo.pmc = event.value;
  }

  onPmcCleared(_event) {
    this.newAccountInfo.pmc = null;
  }

  // Handle Contacts logic
  toggleFormAddContacts() {
    this.hasFormAdditionalContacts = !this.hasFormAdditionalContacts
    this.additionalContactsForm = { ...additionalContactFormInitialValues };
    this.additionalContactsError = '';
  }

  onHandleAddContacts() {
    if (
      this.additionalContactsForm.firstName &&
      this.additionalContactsForm.lastName &&
      this.additionalContactsForm.email &&
      this.additionalContactsForm.jobTitle &&
      this.additionalContactsForm.type
    ) {
      if (!this.newAccountInfo.additionalContacts?.length) {
        this.newAccountInfo.additionalContacts = [];
      }
      // If additional contacts form type === 'notAssigned' set type null
      if(this.additionalContactsForm.type === contactTypes[4]) {
        this.additionalContactsForm.type = null;
      }
      this.newAccountInfo.additionalContacts.push({ ...this.additionalContactsForm });
      this.toggleFormAddContacts();
    } else {
      this.additionalContactsError = 'Please populate all needed fields.';
    }
  }

  onRemoveContact(index) {
    this.newAccountInfo.additionalContacts.splice(index, 1)
  }

  // Group logic
  openGroupModal(content) {
    this.newGroupInfo = {};
    this.selectedGroup = null;
    this.usersList = [];
    this.usersAddedList = [];
    this.groupError = '';
    this.modalService.open(content, {windowClass : 'custom-modal-styles-new create-group-modal'}).result.then().catch((res) => {});
  }

  openConfirmationModal(content) {
    // Group is selected
    if (!this?.selectedGroup?.name) {
      this.groupError = 'Please select a group.';
      return;
    }
    this.groupError = '';
    this.modalService.open(content, {windowClass : 'custom-modal-styles-new create-group-modal'}).result.then().catch((res) => {});
  }

  openChurnConfirmationModal(content) {
    if (!this?.accountToChurn) {
      this.toastService.showError('Please choose an account to churn');
      return;
    }
    this.modalService.open(content, {})
  }

  openChurnStatusModal() {
    this.modalService.open(this.churnStatusModal);
  }

  filterGroups({ query }) {
    const lowerCaseQuery = (query || '').toLowerCase();
    this.suggestionsGroups = this.groups.filter((group) => {
      const name = (group.name || '').toLowerCase();
      return name.includes(lowerCaseQuery) || name.includes(lowerCaseQuery);
    });
  }

  onGroupSelect(group) {
    this.selectedGroup = group;
    this.groupError = '';
    this.usersList = this.accounts.filter((account) => !account?.churned).filter((account) => !group.users.includes(account._id));
    this.usersAddedList = this.accounts.filter((account) => group.users.includes(account._id));
  }

  onGroupCleared() {
    this.selectedGroup = null;
  }

  loadGroups() {
    this.isLoadingGroup = true;
    this.groupsService.getGroups().subscribe((groups) => {
      this.isLoadingGroup = false;
      this.groups = groups;
      this.suggestionsGroups = groups;
    }, (_error) => {
      this.isLoadingGroup = false;
    });
  }

  createGroup() {
    // Do we have minimum required fields
    if (!this.newGroupInfo.name || !this.newGroupInfo.email || !this.newGroupInfo.password) {
      this.groupError = 'Please populate all needed fields.';
      return;
    }
    this.groupError = '';
    this.isLoadingGroup = true;
    // Create Group
    this.groupsService.createGroup(this.newGroupInfo).subscribe(() => {
      this.toastService.show('New group created!', { classname: 'bg-success text-light', delay: 5000 });
      this.modalService.dismissAll();
      this.loadGroups();
    }, (err) => {
      this.isLoadingGroup = false;
      this.groupError = err.some(error => error.includes('User already exists with email')) ? 'Group already exists with email' : 'Error creating group';
    });
  }

  removeGroup() {
    this.groupError = '';
    this.isLoadingGroup = true;
    // Remove Group
    this.groupsService.deleteGroup(this.selectedGroup._id).subscribe(() => {
      this.toastService.show('Group deleted successfully!', { classname: 'bg-success text-light', delay: 5000 });
      this.modalService.dismissAll();
      this.loadGroups();
    }, (_err) => {
      this.isLoadingGroup = false;
      this.groupError = 'Error removing group';
    });
  }

  saveGroup() {
    this.groupError = '';
    this.isLoadingGroup = true;
    const users = this.usersAddedList.map(user => user._id);
    // Remove Group
    this.groupsService.updateGroup(this.selectedGroup._id, { users }).subscribe(() => {
      this.toastService.show('Group saved successfully!', { classname: 'bg-success text-light', delay: 5000 });
      this.modalService.dismissAll();
      this.loadGroups();
    }, (_err) => {
      this.isLoadingGroup = false;
      this.groupError = 'Error saving group';
    });
  }
}
