<div id="steps-wrapper" *ngIf="loaded">
  <p-accordion (onOpen)="onTabOpen($event)">
    <p-accordionTab *ngFor="let step of steps.controls; let stepIndex = index" [selected]="activeStepIndex === stepIndex" class="conclusion-{{ !!step.value.conclusionAction }}">
      <p-header>
        <div class="row" >
          <div class="col-2">
            Follow Up #{{stepIndex + 1}}
            <i *ngIf="stepIndex !== 0" class="far fa-trash-alt" (click)="confirmDeleteStep(stepIndex)"></i>
            <i *ngIf="!step.value.conclusionAction" class="far fa-thin fa-copy" ngbTooltip="Copy Follow Up" (click)="duplicateStep($event, step.getRawValue())"></i>
          </div>
          <div *ngIf="!step.value.conclusionAction && step.controls.templateId.value" class="col-4 step-header-data">
            <p class="truncate"><span>Template:&nbsp;</span> {{this.stepDefaults.get(stepIndex).templateName}}</p>
          </div>
          <div *ngIf="step.value.conclusionAction" class="col-6 step-header-data">
            <p><span>Conclusion Action:&nbsp;</span> {{formatConclusionActionDisplay(step.controls.conclusionAction.value)}}</p>
          </div>
          <div *ngIf="!step.value.conclusionAction && !step.controls.templateId.value" class="col-3 step-header-data">
            <p><span>Template:&nbsp;</span> Not Selected</p>
          </div>
          <div *ngIf="!step.value.conclusionAction && automationSteps.length" class="col-2 step-header-data">
            <p><span>Messages Sent:&nbsp;</span> {{messagesPerStep.get(stepIndex)}}</p>
          </div>
          <div class="col-3 step-header-data">
            <p *ngIf="!hasTriggers() && stepIndex !== 0"><span>Delay:&nbsp;</span> {{step.controls.hoursSinceLastEvent.value}} Hour<span *ngIf="step.controls.hoursSinceLastEvent.value > 1">s</span></p>
            <p *ngIf="!step.value.conclusionAction && hasTriggers() && stepIndex !== 0"><span>Custom Trigger</span></p>
          </div>
        </div>
      </p-header>
      <div class="row steps-container" [formGroup]="step">
        <div class="col-8">
          <div *ngIf="stepIndex > 0 && stepExecutionMode === 'chronologically'" class="form-group">
            <label for="hoursSinceLastEvent">Hours since last Follow Up <i placement="right" ngbTooltip="The number of hours after the previous follow up to wait before running this follow up" class="far fa-info-circle"></i></label>
            <input
              class="form-control"
              id="hoursSinceLastEvent"
              formControlName="hoursSinceLastEvent"
              type="number"
              min="24"
            />
            <app-print-errors controlName="Hours since last Step" [control]="step.controls.hoursSinceLastEvent"></app-print-errors>
          </div>
          <div class="form-group" *ngIf="!!step.value.conclusionAction">
            <label for="conclusionAction">Action <i placement="right" ngbTooltip="What action to take after contact run final follow up" class="far fa-info-circle"></i></label>
            <select
              class="form-control"
              id="conclusionAction"
              formControlName="conclusionAction"
            >
              <option [value]="'mark-lead-as-lost'">Mark Lead As Lost</option>
            </select>
            <app-print-errors controlName="ConclusionAction" [control]="step.controls.conclusionAction"></app-print-errors>
          </div>
          <ng-container *ngIf="!step.value.conclusionAction">
            <!-- TODO: Assume Resident Outreach for now... -->
            <div *ngIf="stepExecutionMode === 'independently' && automationName === 'Resident Outreach Automation'" class="form-group">
              <label>Follow Up Trigger <i placement="right" ngbTooltip="The trigger that will cause the specific automation follow up to execute" class="far fa-info-circle"></i></label>
              <input
                *ngIf="stepIndex === 0"
                class="form-control"
                placeholder="This follow up is triggered when the new resident's move in date is within one week of the current date."
                disabled="disabled"
              />
              <input
                *ngIf="stepIndex === 1"
                class="form-control"
                placeholder="This follow up is triggered when a resident's move in date is between 1 and 2 months in the past."
                disabled="disabled"
              />
              <input
                *ngIf="stepIndex === 2"
                class="form-control"
                placeholder="This follow up is triggered when a resident's move in date is between 3 and 4 months in the past."
                disabled="disabled"
              />
              <input
                *ngIf="stepIndex === 3"
                class="form-control"
                placeholder="This follow up is triggered when a resident's move in date is between 6 and 7 months in the past."
                disabled="disabled"
              />
            </div>
            <div *ngIf="stepExecutionMode === 'independently' && automationName !== 'Resident Outreach Automation'" class="form-group">
              <label>Follow Up Trigger <i placement="right" ngbTooltip="The trigger that will cause the specific automation follow up to execute" class="far fa-info-circle"></i></label>
              <input
                class="form-control"
                placeholder="This follow up is triggered by a custom Nurture Boss trigger."
                disabled="disabled"
              />
            </div>

            <div class="form-group">
              <label for="templateId">Template <i placement="right" ngbTooltip="The template to use for this follow up" class="far fa-info-circle"></i></label>
              <select
                class="form-control"
                id="templateId"
                formControlName="templateId"
              >
                <option *ngFor="let template of templates" [value]="template._id">{{template.templateName}}</option>
              </select>
              <app-print-errors controlName="Template" [control]="step.controls.templateId"></app-print-errors>
            </div>
            <div class="form-group">
              <label>Follow Up Name  <i placement="right" ngbTooltip="What would you like to name this follow up? Prospects and residents will not see this" class="far fa-info-circle"></i></label>
              <input
                class="form-control"
                id="name"
                formControlName="name"
                type="text"
                [attr.disabled]="isSystemAutomation ? 'disabled' : undefined"
                [class.disabled]="isSystemAutomation ? 'disabled' : undefined"
                />
              <app-print-errors controlName="Follow Up Name" [control]="step.controls.name"></app-print-errors>
            </div>
            <div *ngIf="activeTemplateSupportsEmail" class="form-group">
              <label for="emailSubjectLine">Email Subject Line <i placement="right" ngbTooltip="The email subject line sent with emails" class="far fa-info-circle"></i></label>
              <app-spam-warnings
                [shouldDisplay]="true"
                [warningText]="'WARNING: Your Email Subject contains words that are known to trigger Spam Filters for various Email Service Providers. The problamatic words found are: '"
                [emailSubjectLineSpamWarnings]="emailSubjectLineSpamWarningsList[stepIndex]"
              ></app-spam-warnings>
              <app-subject-line-grader-input
                [inputFormGroup]="step"
                [inputId]="'emailSubjectLine' + stepIndex"
                [inputFormControlName]="'emailSubjectLine'"
                [control]="step.controls.emailSubjectLine"
              ></app-subject-line-grader-input>
              <app-character-count [control]="step.controls.emailSubjectLine" [type]="'subject'"></app-character-count>
              <app-print-errors controlName="Email Subject Line" [control]="step.controls.emailSubjectLine"></app-print-errors>
              <app-token-container
                *ngIf="step.controls.templateId.value"
                title="Use tokens to customize your message"
                [formGroup]="step"
                controlName="emailSubjectLine"
                [textSourceElementId]="'emailSubjectLine' + stepIndex"
                [tokensToHide]="getTokensToHide()"
                [validatorsList]="[{name: 'required', value: true}]"
                [tokensArray]="getTokensArray(step.controls.templateId.value, ['apartmentName'])"
              >
              </app-token-container>
            </div>
            <div *ngIf="activeTemplateSupportsText" class="form-group">
              <label for="textMessage">Text Message Content <i placement="right" ngbTooltip="The text message content for text messages" class="far fa-info-circle"></i></label>
              <textarea
                class="form-control"
                maxlength="319"
                [id]="'textMessage' + stepIndex"
                formControlName="textMessage"
              ></textarea>
              <app-character-count [type]="'textmessage'" [maxCharacters]="319" [control]="step.controls.textMessage"></app-character-count>
              <app-print-errors controlName="Text Message Content" [control]="step.controls.textMessage"></app-print-errors>
              <app-token-container
                *ngIf="step.controls.templateId.value"
                title="Use tokens to customize your message"
                [formGroup]="step"
                controlName="textMessage"
                [textSourceElementId]="'textMessage' + stepIndex"
                [tokensToHide]="getTokensToHide()"
                [validatorsList]="[{name: 'required', value: true}]"
                [tokensArray]="getTokensArray(step.controls.templateId.value, ['apartmentName', 'shortUrl'])"
              >
              </app-token-container>
            </div>
            <div *ngIf="!step.controls.templateId.value" class="required-template-defaults">
              <div class="alert alert-danger">
                Select a template to set defaults.
              </div>
            </div>
            <div *ngIf="step.controls.templateId.value" class="required-template-defaults">
              <div *ngIf="validStepDefaults.get(activeStepIndex)">
                <button (click)="openDefaults(defaults)" class="button button-light button-small margin-top-12">
                  <span><i class="far fa-save"></i></span>
                  Update Follow Up Defaults
                </button>
                <div class="alert alert-success">
                  Template defaults set.
                </div>
              </div>
              <div *ngIf="!validStepDefaults.get(activeStepIndex)">
                <button (click)="openDefaults(defaults)" class="button button-light button-small margin-top-12">
                  <span><i class="far fa-save"></i></span>
                  Set Follow Up Defaults
                </button>
                <div class="alert alert-danger">
                  Template defaults not set.
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="!step.value.conclusionAction" class="col-4 iphone-preview">
          <app-iphone-preview
            [_spamLanguageService]="getSpamLanguageGroup(stepIndex).spamLanguageService"
            [emailUrl]="previewUrl"
            [nurturePageUrl]="pagePreviewUrl"
            [removeNurturePagePreview]="!!isVLA"
            [forceDisplayType]="forcedTemplateDisplay"
            helperText="Please select a template and set defaults"
            errorText="Something went wrong when generating your preview!"
          ></app-iphone-preview>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
  <div *ngIf="!hasConclusionStep()" class="form-group step-buttons">
    <button
      [attr.disabled]="steps.controls.length >= 15 ? 'disabled' : undefined"
      type="button"
      class="button button-white margin-top-12"
      (click)="addNewStep()"
    >
      Add New Follow Up
    </button>
    <button
      *ngIf="canUseConclusionStep && stepExecutionMode === 'chronologically' && steps.controls.length > 0"
      [attr.disabled]="(isConclusionStepDisabled || steps.controls.length >= 10) ? 'disabled' : undefined"
      type="button"
      class="button button-white margin-top-12"
      (click)="addConclusionStep()"
    >
      Add Conclusion
    </button>
  </div>

  <ng-template #defaults let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="template-defaults-picker">Automation Template Defaults</h5>
      <div class="float-right">
          <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)"><i class="far fa-times" aria-hidden="true"></i></button>
      </div>
    </div>
    <div class="modal-body">
      <app-template-form-component
        submitButtonLabel="Set Template Defaults"
        [template]="selectedTemplate"
        [templateDefaults]="activeStepDefaults"
        (formSubmitEvent)="handleFormSubmitEvent($event)"
        [useSpamLanguageService]="false"
      ></app-template-form-component>
    </div>
  </ng-template>

  <nb-modal
    [name]="'delete-step-modal'"
    [header]="'Delete Step'"
    [confetti]="false"
    class="delete-step-modal-wrapper"
  >
    <div class="delete-step">
      <div class="alert alert-danger">
        Are you sure you want to delete <strong>Follow Up #{{modalSelectedStepIndex + 1}}</strong>?
      </div>
      <p>
        Once deleted, you will need to manually re-create the follow up if needed.
      </p>
      <div class="d-flex p-2 justify-content-end">
        <button class="button button-danger mr-2" (click)="deleteStep(modalSelectedStepIndex)">Delete</button>
        <button class="button button-white no-margin" (click)="closeModal('delete-step-modal');">Cancel</button>
      </div>
    </div>
  </nb-modal>
</div>